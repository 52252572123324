<template>
  <div class="home">
    <div class="head-img">
      <img src="../assets/attract-top.png" alt="">
    </div>
    <div class="w1200 attract-intor-box">
      <div class="left">
        <!-- 景区概况 -->
        <div class="scenic-overv">
          <h2>景区概况</h2>
          <p>
            刘家峡水库位于黄河上游，位于甘肃临夏永靖县城西南1公里处，距兰州市75公里。有许多峡谷和川地相间出现。河水穿过千岩壁立的深邃峡谷，水势有如万马奔腾，景色十分壮观，其中最著名的要算是刘家峡。黄河河水来到这里，转了一个九十度急弯，然后穿过峡谷向西流去 。
          </p>
        </div>
        <!-- 开放时间 -->
        <div class="open-hours">
          <h2>开放时间</h2>
          <p>夏季：9:00 - 20:00</p>
          <p>冬季：9:00 - 20:00</p>
        </div>
      </div>
      <div class="right">
        <div class="title-box">景区概况</div>
        <h3 class="attract-name">炳灵寺石窟</h3>
        <p class="attract-intro">
          炳灵寺石窟位于甘肃省永靖县西南约52公里小积石山大寺沟内，是甘肃陇中地区规模最大、历史最久和延续时间最长的一座石窟寺。是世界文化遗产、国家首批重点文物保护单位，中国著名的六大石窟之一。
        </p>
        <p class="attract-intro">
          炳灵寺石窟始建于十六国西秦时期，距今1600多年，历经北魏、北周、隋、唐、宋、西夏、元、明、清等朝代，现存窟龛216个，共计造像815尊，壁画约1000平方米，各类佛塔56座，馆藏文物438件。特别是169窟发现的现存国内最早的造像题记——西秦建弘元年（公元420年）的墨书造像铭文和留存于窟内四壁的大量西秦时期的造像与壁画，为炳灵寺石窟的最早开窟年代提供了十分可靠的依据，也为我国石窟早期造像的断代起了标尺的作用。
        </p>
        <p class="attract-intro">
          炳灵寺因保存有中国石窟最早期、中期和最晚期的壁画、石雕，被誉为“石窟的百科全书”。对研究我国石窟的发展演变及雕塑艺术具有很高的历史价值和艺术价值、学术价值。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/sk01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/sk02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/sk03.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/sk04.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/sk05.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">太极岛</h3>
        <p class="attract-intro">
          黄河出刘家峡便转弯向西而去，又在盐锅峡大坝前形成了一个人造湖泊，因状如太极，故名太极湖。太极湖中有大小岛屿9个，面积4000亩，周边有百年枣林1万多亩，鱼塘2000亩，荷花500亩，是西北地区典型的黄河湿地自然景观，岛上碧水如镜，苇花如织，荷叶田田，枣林蓊郁，稻菽起浪，银鲤跃波，红鸥翔集，构成了一幅美不胜收的画卷，展现了陇上江南的无穷魅力，是垂钓赏荷、生态观光、休闲度假的理想之地。
          太极岛绿道公园
        </p>
        <p class="attract-intro">
          太极岛绿道公园全长近10公里,串联了十二个景观节点,通过“一廊”“五园”的结构,打造了不同特色、不同内涵的主题公园。这里是集自然观光、休闲度假、水上娱乐、生态体验为一体的旅游精品景点,是黄河中上游最大的人工湿地自然保护区的重要组成部分,这里也是永靖旅游开发和甘肃生态旅游的一大亮点,现已成为永靖县对外形象展示的一张靓丽名片。
        </p>
        <h3 class="attract-sub-name">太极湖景区</h3>
        <p class="attract-intro">
          太极岛位于县城以西，滔滔黄河到了刘家峡这块宝地，驻足逗留，缓慢西行，奇迹般地来了一个“S”形大转弯，形成一幅阴阳八卦太极图，故而得名太极湖。太极湖水域面积17.3平方公里，湖容量为2.2亿立方米，湖中有大小岛屿9个，占地面积4000多亩。岛上柳树成荫，芦苇成片，鱼儿戏嬉，水鸟频鸣，恍若置身于美丽幽静的江南水乡。这里是集自然观光、休闲度假、水上娱乐、生态体验为一体的旅游精品景点，是黄河上游最大的人工湿地自然保护区的重要组成部分，这里也是永靖旅游开发和甘肃生态旅游的一大亮点，是西北地区典型的黄河湿地自然景观。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/tjh01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/tjh02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/tjh03.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/tjh04.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">刘家峡恐龙国家地质公园</h3>
        <p class="attract-intro">
          刘家峡恐龙国家地质公园位于甘肃省永靖县太极湖北岸，东距省会兰州40公里，东南距县城10公里，交通极为便利。
        </p>
        <p class="attract-intro">
          公园内刘家峡恐龙足印群，保存十分完整和清晰，立体感强。同时，在同一岩层层面上还保存有恐龙卧迹、尾部拖痕及粪迹等，构成了足印、卧迹、拖痕和粪迹共存的场面，非常独特。在已揭露的总计2000平方米的岩层层面上至少有两类巨型晰脚类，两类兽脚类，一类似鸟龙类，一类翼龙类和其它三类形态独特、尚未归属的足印，代表至少有9个属种。在同一地点出现如此多样的食植类和食肉类恐龙足印，在国内尚属首次，在世界上也极为罕见。其中最大的一组蜥脚类足印后足印长150厘米，宽120厘米，前足印长70厘米，宽110厘米，步幅375厘米，左右足印外侧缘间距345厘米，为世界之最，专家们确定，留下此足印的恐龙，两步就可以跨过一个篮球场，体长至少有20米，体重接近50吨，而且该类足印前足小，后足大，前后足足印成对出现并有规律地部分叠覆，为国内外首次发现。总之，该恐龙足印化石规模之大、种类之多、保存之完好、清晰度之高、立体感之强，均为世界少有。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/kl01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/kl02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/kl03.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/kl04.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">黄河水电博览园</h3>
        <p class="attract-intro">
          黄河水电博览园位于刘家峡大坝码头片区,这里是黄河三峡旅游区的重要门户，也是进出永靖的标志性门户。总占地面积360亩，总建筑面积2.6万平方米，停车位800多个，项目总投资5.7亿元，博览园分入口商业服务区、治水名人园区、港航配套服务区、治水文化展示区四大功能区。建有游客服务中心、迎宾大道、生态停车场、山海经雕塑广场、黄河水电博览馆、报恩寺等。黄河水电博览园是全国唯一的治水文化主题景区、全国唯一的水电博览中心，是黄河三峡的地标性建筑和旅游集散中心，是一处集中展示大禹文化、黄河文化的承载地，极大提升了黄河三峡旅游的品牌和形象。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/hhsd01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/hhsd02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/hhsd03.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">黄河文化博物馆</h3>
        <p class="attract-intro">
          黄河文化博物馆以“黄河情·水电魂·中国梦”作为布展主题，它是以黄河为宏大的叙事背景，将黄河文化、黄河治理、黄河水电、黄河风光等内容精心编排，运用高科技展览手段，给观众以文化的滋养和精神的洗礼。丰富多彩的展示内容，生动有趣的布展形式介绍了历史悠久的黄河文化，展现了黄河治理取得的卓越成就，反映了黄河流域永靖县周边的风土人情，憧憬水利水电事业发展的美好明天。
        </p>
        <p class="attract-intro">
          2016年11月被甘肃省委党史研究室命名为“甘肃省中共党史教育基地”。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/hhwh01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/hhwh02.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">刘家峡国际滑翔营地</h3>
        <p class="attract-intro">
          刘家峡国际滑翔营地位于永靖县西部贫困山区杨塔乡赵山村（全国旅游扶贫重点村），刘家峡水库(炳灵湖)北岸，属全县重点旅游扶贫项目。该营地具有上升气流充足、风向稳定、环境优美的自然条件，是开展滑翔伞航空体育运动的理想之地。起飞场为杨塔乡赵山村的古丝绸之路长夷岭——黄家大山，海拔2300米，降落场为刘家峡水库魏家坡跟，海拔1720米，是一处休闲度假、户外运动的理想之地，也是体验离地高飞、融入蓝天、俯视山川河流的最佳场所，被国内高空滑翔界堪称“中国的博卡拉，世界的刘家峡”，受到国内喜欢冒险和挑战的人士的关注和青睐。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/gkhx01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/gkhx02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/gkhx03.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/gkhx04.png" alt="">
          </div>
        </div>
        <h3 class="attract-name">黄河三湾</h3>
        <p class="attract-intro">
          黄河三湾位于炳灵湖北岸，风景怡人、水面宽广、视野开阔，是休闲避暑绝佳之地。七月的黄河三湾被千亩油葵花海点缀的异彩纷呈，千人音乐会、户外狂欢节在这里精彩绽放……这里碧波荡漾、花椒飘香，有回归自然、返璞归真的美好氛围，是绝好的生态观光地。黄河三湾充分利用滨河、沙滩、田园等自然景观建成永靖特色农家乐，有农业园区观光和田园采摘活动，是品尝新鲜时蔬、农家土鸡、黄河大鲤鱼的好去处。黄河三湾有“全亚洲最适合滑翔的基地”，可与堪称世界三大滑翔基地的博卡拉相媲美。
        </p>
        <div class="img-list">
          <div class="item">
            <img src="../assets/hhsw01.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/hhsw02.png" alt="">
          </div>
          <div class="item">
            <img src="../assets/hhsw03.png" alt="">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScenicIntro",

}
</script>

<style scoped lang="scss">
  .head-img{
    width: 100%;
    height: 316px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .attract-intor-box{
    margin-top: 20px;
    display: flex;
    .left{
      width: 223px;
      margin-right: 40px;
      .scenic-overv{
        box-sizing: border-box;
        width: 100%;
        min-height: 332px;
        padding: 15px 11px;
        border: 1px solid #707070;
        margin-bottom: 25px;
        text-align: left;
        h2{
          line-height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 15px;
        }
        p{
          font-size: 14px;
          line-height: 2;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #000000;
        }
      }
      .open-hours{
        box-sizing: border-box;
        width: 100%;
        height: 132px;
        background: #FFFFFF;
        border: 1px solid #707070;
        text-align: left;
        padding: 15px 14px;
        h2{
          line-height: 24px;
          font-size: 18px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 15px;
        }
        p{
          font-size: 14px;
          line-height: 2;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #000000;
          margin-bottom: 5px;
        }
      }
    }
    .right{
      flex: 1;
      margin-bottom: 36px;
      .title-box{
        box-sizing: border-box;
        width: 131px;
        height: 43px;
        line-height: 43px;
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        padding-left: 20px;
        text-align: left;
        background-image: url(../assets/attract-title-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 13px;
      }
      .attract-name{
        line-height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        padding-left: 20px;
        position: relative;
        text-align: left;
        margin-bottom: 15px;
        &::after{
          content: "";
          width: 4px;
          height: 18px;
          background: #7DBFF6;
          position: absolute;
          top: 1.5px;
          left: 0;
        }
      }
      .attract-sub-name{
        font-size: 14px;
        color: #000;
        font-weight: bold;
        line-height: 30px;
        text-align: left;
      }
      .attract-intro{
        font-size: 14px;
        line-height: 2;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #000000;
        text-align: left;
      }
      .img-list{
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        .item{
          width: 296px;
          height: 166px;
          margin: 0 24px 22px 0 ;
          img{
            width: 100%;
            height: 100%;
          }
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 780px) {
    .home{
      .head-img{
        height: 7.8rem;
      }
      .attract-intor-box{
        box-sizing: border-box;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        .left{
          width: 100%;
          margin-right: 0;
          .scenic-overv{
            box-sizing: border-box;
            width: 100%;
            min-height: auto;
            padding: 0.8rem;
            border: 1px solid #707070;
            margin-bottom: 1.2rem;
            h2{
              line-height: 1.6;
              font-size: 0.8rem;
              font-weight: bold;
              margin-bottom: 0.5rem;
            }
            p{
              font-size: 0.7rem;
              line-height: 1.8;
              color: #000000;
            }
          }
          .open-hours{
            box-sizing: border-box;
            width: 100%;
            height: auto;
            padding: 0.8rem;
            h2{
              line-height: 1.6;
              font-size: 0.8rem;
              margin-bottom: 0.5rem;
            }
            p{
              font-size: 0.7rem;
              line-height: 1.6;
              margin-bottom: 5px;
            }
          }
        }
        .right{
          flex: 1;
          margin-bottom: 2rem;
          padding-top: 1rem;
          .title-box{
            display: none;
          }
          .attract-name{
            line-height: 1rem;
            font-size: 0.8rem;
            padding-left: 1rem;
            margin-bottom: 0.6rem;
            margin-top: 0.5rem;
            &::after{
              content: "";
              width: 0.2rem;
              height: 0.9rem;
              top: 0.1rem;
              left: 0;
            }
          }
          .attract-sub-name{
            font-size: 0.7rem;
            line-height: 1.8;
          }
          .attract-intro{
            font-size: 0.7rem;
            line-height: 1.8;
          }
          .img-list{
            margin-top: 0.8rem;
            .item{
              width: 100%;
              height: 8.3rem;
              margin: 0 0 0.5rem 0;
              img{
                width: 100%;
                height: 100%;
              }
              &:nth-child(3n){
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
</style>